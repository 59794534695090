
  import { Component, Mixins } from 'vue-property-decorator';
  import { Action, State as StateClass, Getter } from 'vuex-class';
  import Chart from 'chart.js';
  import { State } from '@/models/State';
  import LineChart from '@/components/common/LineChart.vue';
  import LoadingHandler from '@/components/mixins/LoadingHandler.vue';
  import { bloqifyFirestore, firebase } from '@/boot/firebase';
  import { PaymentStatus } from '@/models/investments/Investment';
  import { IdentificationRequestStatus } from '@/models/identification-requests/IdentificationRequest';

  @Component({
    components: {
      LineChart,
    },
  })
  export default class Dashboard extends Mixins(LoadingHandler) {
    chartOptions = {
      tooltips: {
        enabled: true,
        callbacks: {
          label(tooltipItems: any, data: any) {
            return `${tooltipItems.yLabel} eur`;
          },
        },
      },
    };

    @Action bindFirestoreReferences!: Function;
    @Action unbindFirestoreReferences!: Function;

    @StateClass boundPayments!: State['boundPayments'];
    @StateClass boundCounts!: State['boundPayments'];

    @Getter getPendingChangeRequests!: State['dataChangeRequests'];

    async mounted(): Promise<void> {
      const year = new Date().getUTCFullYear();
      this.handleLoading(this.bindFirestoreReferences([
        {
          name: 'boundPayments',
          ref: bloqifyFirestore.collectionGroup('payments')
            .where('providerData.status', '==', PaymentStatus.Paid)
            .where('createdDateTime', '>=', firebase.firestore.Timestamp.fromMillis(Date.UTC(year, 0, 1)))
            .where('createdDateTime', '<=', firebase.firestore.Timestamp.fromMillis(Date.UTC(year, 11, 31)))
            .where('deleted', '==', false)
            .orderBy('createdDateTime', 'asc'),
          options: {
            maxRefDepth: 0,
          },
        },
        {
          name: 'boundCounts',
          ref: bloqifyFirestore.collection('settings').doc('counts'),
        },
      ]));
    }

    beforeDestroy(): void {
      this.unbindFirestoreReferences([
        { name: 'boundPayments' },
        { name: 'boundCounts' },
      ]);
    }

    get chartData(): Chart.ChartData {
      const transformed = this.boundPayments?.reduce((transformedArray, payment): any[] => {
        const month = (payment.paymentDateTime || payment.createdDateTime).toDate().getUTCMonth();
        transformedArray[month] += payment.providerData.metadata.euroAmount;
        return transformedArray;
      }, Array.from({ length: 12 }, (): number => 0)) || [];

      return {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
          {
            label: 'GitHub Commits',
            data: transformed,
          },
        ],
      };
    }
  }
